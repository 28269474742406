<template>
		
	<div class="list">
			<!-- <shopsearch>1111</shopsearch> -->
			
		<Card style='margin-left: 200px;margin-top: -20px;'>
			<!-- <div class="titles">店铺列表</div> -->
			<div >
				
				<div class="title">
					<slot></slot>
				</div>
				<!-- 行业编辑 -->
				<Modal v-model="modal1" title="添加行业信息" @on-ok="ok" @on-cancel="cancel">
					<Form ref="formValidate" :model="formValidate" :label-width="80">
						<FormItem label="行业名称" prop="name">
							<Input v-model="formValidate.name" placeholder="请输入行业名称"></Input>
						</FormItem>
						<!-- <FormItem label="排序" prop="ranking">
							<Input v-model="formValidate.ranking" placeholder="请输入排序"></Input>
						</FormItem> -->
					</Form>
				</Modal>
				<!-- 类目编辑 -->
				<Modal v-model="modal2" title="添加主营类目" @on-ok="ok" @on-cancel="cancel">
					<Form ref="formValidate1" :model="formValidate1" :label-width="80">
						<FormItem label="类目名称" prop="name">
							<Input v-model="formValidate1.name" placeholder="请输入类目名称"></Input>
						</FormItem>
					</Form>
					<el-form ref="form" :model="formValidate1" label-width="80px">
						<el-form-item label="所属行业">
							<el-select v-model="formValidate1.ranking" placeholder="请选择所属行业">
								<el-option :label="item.i_name" :value="item.i_id" v-for="item,index in datas" :key='index'></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</Modal>
	
				<div class="content">
					<div id="contents">
					<!-- 	<div class="title">		
						<div class="selname">商家类型:</div>
						<Select v-model="value" placeholder="请选择" class="sel"> -->
						<!-- <Option   value="">全部</Option> -->
					<!-- 	<Option   v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value" @click.native="shopOpt(item.value)"></Option>
		                </Select>
						</div>	 -->
						<div class="cont">
			
					
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==0">
									<td>{{item2.role_name}}</td>
									<td>{{item2.content}}</td>
									<td>{{item2.ctime}}</td>
									<td @click="start(item2.role_id,item2.is_start)">
										<el-switch v-model="item2.is_start" active-color="#13ce66" inactive-color="#ff4949">
										</el-switch>

									</td>
									<td class="oper">
										<div class="stte" @click="jurisdiction(item2.role_id,item2.role_name)">权限设置</div>
										<div class="com" @click="compile(item2.role_name,item2.content,item2.role_id)">编辑</div>
										<div class="del" @click="del(item2.role_id)">删除</div>
									</td>
								</tr>

								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==1">
									<td>{{item2.admin_name}}</td>
									<td>{{item2.admin_user==null?'暂无':item2.admin_user}}</td>
									<td>{{item2.admin_tel==''?'暂无':item2.admin_tel}}</td>
									<td>{{item2.admin_email==null?'暂无':item2.admin_email}}</td>
									<td>{{item2.admin_time}}</td>
									<td>{{item2.last_logon_time==null?'暂无':item2.last_logon_time}}</td>
									<td @click="start2(item2.admin_id,item2.is_start)">
										<el-switch v-model="item2.is_start" active-color="#13ce66" inactive-color="#ff4949">
										</el-switch>
									</td>
									<td class="oper">
										<div class="com" @click="compiles(item2.admin_id)">编辑</div>
										<div class="del" @click="open(item2.admin_id)">删除</div>
									</td>
								</tr>

								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==2">
									<td>{{item2.admin_name}}</td>
									<td>{{item2.admin_name}}</td>
									<td>{{item2.admin_user==null?'暂无':item2.admin_user}}</td>
									<td>{{item2.admin_tel==''?'暂无':item2.admin_tel}}</td>
									<td>{{item2.admin_email==null?'暂无':item2.admin_email}}</td>
									<td>{{item2.admin_time}}</td>
									<td>{{item2.last_logon_time==null?'暂无':item2.last_logon_time}}</td>
									<td @click="start2(item2.admin_id,item2.is_start)">
										<el-switch v-model="item2.is_start" active-color="#13ce66" inactive-color="#ff4949">
										</el-switch>
									</td>
									<td class="oper">
										<div class="com" @click="compiles(item2.admin_id)">编辑</div>
										<div class="del" @click="open(item2.admin_id)">删除</div>
									</td>
								</tr>


								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==4">
									<td>
										<CheckboxGroup v-model="social">
											<Checkbox :label="item2.i_id"></Checkbox>
										</CheckboxGroup>
									</td>
									<td>{{item2.i_id}}</td>
									<td>{{item2.i_name}}</td>
									<td>{{item2.sda}}</td>
									<!-- <td>{{item2.i_order}}</td> -->
									<td class="oper">
										<div class="com" @click="i_bianji(item2.i_id,item2.i_name,item2.i_order)">编辑</div>
										<div class="del" @click="delate4(item2.i_id)">删除</div>
									</td>
								</tr>




								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==5">
									<td>
										<CheckboxGroup v-model="social">
											<Checkbox :label="item2.i_id"></Checkbox>
										</CheckboxGroup>
									</td>
									<td>{{item2.c_name}}</td>
									<td>{{item2.i_name}}</td>
									<td class="oper">
										<div class="com" @click="leimualter(item2.c_id,item2.c_name,item2.i_id)">编辑</div>
										<div class="del" @click="leimudel(item2.c_id)">删除</div>
									</td>
								</tr>


								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==6">
									<td>
										<CheckboxGroup v-model="social">
											<Checkbox :label="item2.b_id"></Checkbox>
										</CheckboxGroup>
									</td>
									<td>{{item2.b_id}}</td>
									<td>{{item2.b_name}}</td>
									<td>{{item2.b_Initials}}</td>
									<!-- <td>{{item2.b_order}}</td> -->
									<td @click="brandStart(item2.b_id,item2.b_show)">
										<el-switch v-model="item2.b_show==1?true:false" active-color="#13ce66" inactive-color="#ff4949">
										</el-switch>
									</td>
									<td>{{item2.i_name}}</td>
									<td class="oper">
										<div class="com" @click="brandcom(item2.b_id)">编辑</div>
										<div class="del" @click="branddel(item2.b_id)">删除</div>
									</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==7">
									<td>{{item2.storeid}}</td>
									<td v-if="admin_types==1">{{item2.name_type}}</td>
									<td>{{item2.s_name}}</td>
									<td>{{item2.user}}</td>
									<td>{{item2.b_name}}</td>
									<td>{{item2.i_name}}</td>
									<td>{{item2.su_name}}</td>

									<td>{{item2.c_name}}</td>
									<td>{{item2.start_time}}</td>
									<td>{{item2.end_time}}</td>
									<td class="oper">
										<div class="com" @click="bianji7(item2.storeid)">编辑</div>
										<div class="del" @click="delate7(item2.storeid)">删除</div>
										<div v-if="admin_types==1&&item2.level==1" class="del" @click="add7(item2.storeid)">添加</div>
									</td>
								</tr>
							</table>
						</div>
						<compile :iscompile='isshow' @compX='compileX' :content='content' :name='name' :ids='ids' @refresh='btnrefresh'></compile>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
	import compile from './Compile.vue'
	// import shopsearch from './search.vue'
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			},
		},
		data() {
			// console.log(contentC)
			var t=this.$props.contentC
			console.log('1',t)
			return {
				        options: [{
				          value: '1',
				          label: '商户'
				        },
						{
				          value: '2',
				          label: '门店'
				        }],
				value:'',
				
			
				math: {
					item1: true,
					item2: false,
				},
				social: [],
				isshow: false,
				name: null,
				content: null,
				ids: null,
				formValidate: {
					name: '',
					// ranking: ''
				},
				formValidate1: {
					name: '',
					ranking: '',
					c_id: ''
				},
				modal1: false,
				modal2: false,
				ids: null,
				datas: '',
				admin_types:''
			}
		},
		created() {
			console.log(this.flag)
			let admin_type = this.$storage.getLocal('type');
			   this.admin_types=admin_type
			let token = this.$storage.getLocal('token');
			this.$request.selIndustryList({
				token
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data
				}
			})
		},
		components: {
			compile
		},
		methods: {
			bianji7(id) {
				this.$router.push({path:'/addshop?id='+id});
			},
			add7(id) {
    this.$confirm('此操作为该商户多添加一个门店权限?', '提示', {
     confirmButtonText: '确定',
     cancelButtonText: '取消',
     type: 'warning'
    }).then((res) => {
     let token = this.$storage.getLocal('token');
     console.log(id)
     this.$request.addStoreNumber({
      id,
      token
     }).then(res => {
      if (res.code == 0) {
             this.$message({
                   message: '添加成功',
                   type: 'success'
                 });
      } else {
       this.$message.error('添加失败');
      }
     })
    })
   },
			
			
		shopOpt(value){
			// this.$request.level({
				
			// })
		},
			
			brandcom(id) {
				this.$router.push({path:'/addbrand?id='+id});
			},
		
			branddel(id) {
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let token = this.$storage.getLocal('token');
					this.$request.brandDel({
						token,
						id
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

			brandStart(id, show) {
				let i_show = show == 1 ? 2 : 1
				let token = this.$storage.getLocal('token');
				this.$request.upIsShow({
					token,
					id,
					show: i_show
				}).then(res => {
					this.$emit("add", 'ok');
					if (res.code == 0) {
						this.$emit("del", 'ok');
						this.$notify({
							title: '成功',
							message: '成功',
							type: 'success'
						});
					} else {
						this.$notify({
							title: '警告',
							message: '失败',
							type: 'warning'
						})
					}
				})
			},


			leimualter(id, name, id2) {
				this.modal2 = true;
				this.formValidate1.name = name;
				this.formValidate1.ranking = id2;
				this.formValidate1.c_id = id;
			},
			leimudel(id) {
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let token = this.$storage.getLocal('token');
					this.$request.delCategory({
						token,
						id
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			ok() {
				if (this.flag == 4) {
					let token = this.$storage.getLocal('token');
					this.$request.upIndustry({
						token,
						name: this.formValidate.name,
						ranking: this.formValidate.ranking,
						id: this.ids
					}).then(res => {
						if (res.code == 0) {
							this.$Message.info('编辑成功');
							this.$emit("add", 'ok');
						} else {
							this.$Message.info('编辑失败:' + res.msg);
						}
					})
				} else if (this.flag == 5) {
					let token = this.$storage.getLocal('token');
					this.$request.upCategory({
						token,
						name: this.formValidate1.name,
						i_id: this.formValidate1.ranking,
						c_id: this.formValidate1.c_id
					}).then(res => {
						if (res.code == 0) {
							this.$Message.info('编辑成功');
							this.$emit("add", 'ok');
						} else {
							this.$Message.info('编辑失败:' + res.msg);
						}
					})
				}

			},
			cancel() {
				this.$Message.info('已取消');
			},
			i_bianji(id, name, order) {
				this.formValidate.name = name;
				this.formValidate.ranking = order;
				this.ids = id;
				this.modal1 = true
			},
			compiles(id) {
				this.$router.push({
					path: '/addUser',
					query: {
						id: id
					}
				})
				// let token = this.$storage.getLocal('token');
				// this.$request.updateAdminSel({token,id}).then(res=>{

				// 	console.log(res)
				// })
			},
			compileX() {
				this.isshow = false;
			},
			compile(name, content, ids) {
				this.isshow = true;
				this.name = name;
				this.content = content;
				this.ids = ids;
			},
			del(id) {
				let token = this.$storage.getLocal('token');
				this.$request.roleDel({
					id,
					token
				}).then(res => {
					if (res.code == 0) {
						this.$emit("del", 'ok');
					} else {
						this.$emit("del", 'ok');
						this.$notify({
							title: '失败',
							message: '删除失败',
							type: 'warning'
						});
					}
				})
			},
			btnrefresh() {
				this.$emit("del", 'ok');
			},
			start(id, start) {
				let token = this.$storage.getLocal('token');
				this.$request.roleUpIsStart({
					id,
					token,
					start: start ? 1 : 2
				}).then(res => {
					if (res.code == 0) {

					}
				})
			},
			start2(id, start) {
				let token = this.$storage.getLocal('token');
				this.$request.adminUpIsStart({
					token,
					id,
					'start': start ? 1 : 2,
				}).then(res => {
					if (res.code == 0) {
						this.$notify({
							title: '成功',
							message: '成功',
							type: 'success'
						});
					} else {
						this.$notify({
							title: '警告',
							message: '失败',
							type: 'warning'
						})
					}
				})
			},
			
			open(id) {
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let token = this.$storage.getLocal('token');
					console.log(id)
					this.$request.delAdmin({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			jurisdiction(id, name) {
				this.$router.replace({
					path: "/jurisdiction?id=" + id + '&name=' + name,
				}, function() {}, function() {})
			},
             


			bianji4(id) {

			},
			delate4(id) {
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					console.log(id)
					this.$request.delIndustry({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			delate7(id) {
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					console.log(id)
					this.$request.delStoreData({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.list{
		width: 95%;
		margin-left: 2.5%;
		margin-top: 30px;
	}
	table {
		width: 100%;
		background: #eeeeee; 
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.cont
	{
		margin-top: 10px;
		/* margin-left: 10px; */
	}
	.title
	{
		display: flex;
		width: 15%;
		/* margin-left: -0%; */
	}
	.selname
	{
		width: 50%;
		margin-top: 5px;
	}
	.sel
	{
		margin-left: 10px;
	}
	.com,
	.del{
		cursor: pointer;
	}
</style>
